/* eslint import/no-cycle: 0 */
/* eslint max-classes-per-file: 0 */
import Suggestions from './Suggestions';

class AUMapHelpers {
  static getDistanceBetweenPoints(point1, point2) {
    return google.maps.geometry.spherical.computeDistanceBetween(point1, point2);
  }

  static convertFromLatLng(coord) {
    return Math.round(coord * 1000000);
  }

  static convertToLatLng(coord) {
    let c;
    if (coord.length === 7) {
      c = `${coord.substring(0, 1)}.${coord.substring(1)}`;
    }
    if (coord.length === 8) {
      c = `${coord.substring(0, 2)}.${coord.substring(2)}`;
    }
    if (coord.length === 9) {
      c = `${coord.substring(0, 3)}.${coord.substring(3)}`;
    }
    return c;
  }
}

const DENMARK_BOUNDS = {
  north: 57.730016588,
  south: 54.8000145534,
  west: 8.08997684086,
  east: 12.6900061378,
};

class AUMap {
  constructor(
    pContainer,
    width,
    height,
    zoomLevel,
    center,
    scrollWheel,
    draggable,
    clickable,
    streetView,
    onMapClick,
    pco,
    zco,
    svco,
    onTilesLoaded,
  ) {
    const container = pContainer;
    const mapOptions = {
      zoom: zoomLevel,
      center: new google.maps.LatLng(center.lat, center.lng),
      scrollwheel: scrollWheel,
      draggable,
      clickable,
      streetViewControl: streetView,
      mapTypeControlOptions: {
        mapTypeIds: ['default', google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID],
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        position: google.maps.ControlPosition.RIGHT_TOP,
      },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      panControlOptions: typeof pco === 'undefined' ? {} : pco,
      zoomControlOptions: typeof zco === 'undefined' ? {} : zco,
      streetViewControlOptions: typeof svco === 'undefined' ? {} : svco,
      restriction: {
        latLngBounds: DENMARK_BOUNDS,
        strictBounds: false,
      },
    };

    if (container) {
      // Kortets størrelse sættes
      container.style.width = typeof width === 'number' ? `${width}px` : width;
      container.style.height = typeof height === 'number' ? `${height}px` : height;

      // Opretter kort
      this.gaMap = new google.maps.Map(container, mapOptions);
      this.markers = [];
      this.polygons = [];

      this.infoWindow = new google.maps.InfoWindow();
      this.geocoder = new google.maps.Geocoder();

      if (typeof onMapClick === 'function') {
        google.maps.event.addListener(this.gaMap, 'click', (e) => {
          onMapClick(e);
        });
      }

      // This to ensure that the geometry library has loaded
      if (typeof onTilesLoaded === 'function') {
        google.maps.event.addListenerOnce(this.gaMap, 'tilesloaded', () => {
          onTilesLoaded(this);
        });
      }
    }
  }

  setBounds(boundsCenter, fitAll = false) {
    const boundsCenterPoint = new google.maps.LatLng(boundsCenter.lat, boundsCenter.lng);
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach((m) => {
      if (fitAll
        || AUMapHelpers.getDistanceBetweenPoints(boundsCenterPoint, m.getPosition()) < 2000) {
        bounds.extend(m.getPosition());
      }
    });
    this.gaMap.fitBounds(bounds);
  }

  setCenter(marker) {
    this.gaMap.setCenter(marker.getGaMarker().getPosition());
  }

  panTo(location, zoom, id) {
    const panToPoint = new google.maps.LatLng(location.lat, location.lng);
    this.gaMap.panTo(panToPoint);
    this.gaMap.setZoom(zoom);
    if (typeof id !== 'undefined') {
      this.markers.forEach((m) => {
        if (m.get('id') === id) {
          m.setAnimation(google.maps.Animation.BOUNCE);
        } else {
          m.set('bounce', false);
          m.setAnimation(null);
        }
      });
    }
  }

  addMarker(marker) {
    this.markers.push(marker);
  }

  clearMarkers() {
    this.markers.forEach((m) => {
      m.setMap(null);
    });

    this.markers = [];
  }

  addPolygon(polygon) {
    this.polygons.push(polygon);
  }

  clearPolygons() {
    this.polygons.forEach((p) => {
      p.setMap(null);
    });
  }

  getGaMap() {
    return this.gaMap;
  }

  getInfoWindow() {
    return this.infoWindow;
  }

  getGeocoder() {
    return this.geocoder;
  }

  static getIdMarkerNearestToLocation(position, locations) {
    const currentLocation = new google.maps.LatLng(
      position.coords.latitude, position.coords.longitude,
    );
    let shortestDistance = 0;
    let shortestDistanceObj = null;
    locations.forEach((l) => {
      if (l.type === '0') {
        const point = new google.maps.LatLng(l.latitude, l.longitude);
        const distance = AUMapHelpers.getDistanceBetweenPoints(currentLocation, point);
        if (shortestDistance === 0 || distance < shortestDistance) {
          shortestDistance = distance;
          shortestDistanceObj = l;
        }
      }
    });

    return shortestDistanceObj;
  }
}

class AUMapMarker {
  constructor(
    map,
    position,
    icon,
    draggable,
    clickable,
    panTo,
    clickZoom,
    clickZoomLevel,
    windowContent,
    id,
  ) {
    const gaMap = map.getGaMap();
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(position.lat, position.lng),
      map: gaMap,
      icon,
      draggable,
    });

    if (typeof id !== 'undefined') {
      marker.set('id', id);
    }

    // Listeners tilføjes markør
    google.maps.event.addListener(marker, 'click', () => {
      if (windowContent !== '') {
        // Infowindow åbner ved klik
        const iw = map.getInfoWindow();
        iw.open(gaMap, marker);
        iw.setContent(windowContent);
      }

      if (clickable) {
        if (panTo) {
          gaMap.panTo(marker.getPosition());
        }

        if (typeof clickZoom === 'undefined' || gaMap.getZoom() > clickZoomLevel) {
          gaMap.setZoom(gaMap.getZoom());
        } else {
          gaMap.setZoom(clickZoomLevel);
        }
      }
    });

    /* At flytte en markør betragtes som et klik på kortet
     (der giver tilsyneladende ingen problemer,
    skulle der ikke være defineret click-event på kortet) */
    if (draggable) {
      google.maps.event.addListener(marker, 'dragend', (point) => {
        google.maps.event.trigger(gaMap, 'click', point);
      });
    }

    this.gaMarker = marker;
    map.addMarker(marker);
  }

  getGaMarker() {
    return this.gaMarker;
  }
}

class AUMapPolygon {
  constructor(map, coordinates, centerLat, centerLng) {
    const gaMap = map.getGaMap();
    const polygon = new google.maps.Polygon({
      paths: coordinates,
      strokeColor: '#003d85',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#003d85',
      fillOpacity: 0.35,
    });
    polygon.setMap(gaMap);
    map.addPolygon(polygon);
    gaMap.setCenter(new google.maps.LatLng(centerLat, centerLng));
    gaMap.setZoom(18);
  }
}

class AUMapRoute {
  constructor(map, container) {
    const rendererOptions = {
      draggable: true,
    };

    this.directionsService = new google.maps.DirectionsService();
    const directionsDisplay = new google.maps.DirectionsRenderer(rendererOptions);
    directionsDisplay.setMap(map.getGaMap());
    directionsDisplay.setPanel(container);
    this.directionsDisplay = directionsDisplay;
    this.map = map;
  }

  static getTravelModes() {
    return {
      BIL: google.maps.DirectionsTravelMode.DRIVING,
      CAR: google.maps.DirectionsTravelMode.DRIVING,
      GÅ: google.maps.DirectionsTravelMode.WALKING,
      WALK: google.maps.DirectionsTravelMode.WALKING,
      CYKEL: google.maps.DirectionsTravelMode.BICYCLING,
      BICYCEL: google.maps.DirectionsTravelMode.BICYCLING,
      OFFENTLIG: google.maps.DirectionsTravelMode.TRANSIT,
      PUBLIC: google.maps.DirectionsTravelMode.TRANSIT,
    };
  }

  clear() {
    this.directionsDisplay.setMap(null);
  }

  calc(origin, destination, pTravelMode, callback, waypointLocations) {
    const waypoints = [];

    const travelMode = AUMapRoute.getTravelModes()[pTravelMode];

    if (typeof waypointLocations !== 'undefined') {
      waypointLocations.forEach((w) => {
        waypoints.push({
          location: w,
          stopover: false,
        });
      });
    }

    const request = {
      origin,
      destination,
      travelMode,
      waypoints,
    };

    this.directionsService.route(request, (response, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        if (this.directionsDisplay.getMap() === null) {
          this.directionsDisplay.setMap(this.map.getGaMap());
        }
        this.directionsDisplay.setDirections(response);
      }
    });

    if (typeof callback === 'function') {
      callback();
    }
  }

  static getStopWords() {
    return ['FRA', 'TIL', 'AFGANG', 'ANKOMST', 'BIL', 'GÅ', 'CYKEL', 'OFFENTLIG', 'FROM', 'TO', 'DEPARTURE', 'ARRIVAL', 'CAR', 'WALK', 'BICYCLE', 'PUBLIC'];
  }

  static findRoutePattern(pattern, collection, callback) {
    let re = /FRA([^<]*)TIL([^<]*)(AFGANG|ANKOMST|BIL|GÅ|CYKEL|OFFENTLIG)([^<]*)/g;
    let matches = re.exec(pattern);
    if (!matches) {
      re = /FROM([^<]*)TO([^<]*)(DEPARTURE|ARRIVAL|CAR|WALK|BICYCLE|PUBLIC)([^<]*)/g;
      matches = re.exec(pattern);
    }
    if (matches) {
      let from = matches[1].trim();
      let to = matches[2].trim();
      const method = matches[3].trim();
      const time = matches[4].trim();

      const getFromPerson = new Promise((resolve) => {
        Suggestions.getSuggestions(from, collection, (fromResults) => {
          if (fromResults.length > 0) {
            if (fromResults[0].type) {
              resolve(fromResults[0].address);
            } else if (fromResults[0].departments.length > 0) {
              const dep = fromResults[0].departments[0];
              const address = `${dep.address.street} ${dep.address.zip} ${dep.address.city}`;
              resolve(address);
            }
          } else {
            resolve(from);
          }
        });
      });

      const getToPerson = new Promise((resolve) => {
        Suggestions.getSuggestions(to, collection, (toResults) => {
          if (toResults.length > 0) {
            if (toResults[0].type) {
              resolve(toResults[0].address);
            } else if (toResults[0].departments.length > 0) {
              const dep = toResults[0].departments[0];
              const address = `${dep.address.street} ${dep.address.zip} ${dep.address.city}`;
              resolve(address);
            }
          } else {
            resolve(to);
          }
        });
      });

      Promise.all([getFromPerson, getToPerson]).then((values) => {
        [from, to] = values;
        callback({
          from,
          to,
          method,
          time,
        });
      });
    }
    callback(null);
  }
}

class AUMapGeocode {
  static lookupAddress(map, address, callback) {
    const gc = map ? map.getGeocoder() : new google.maps.Geocoder();
    gc.geocode({
      address,
    }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const point = new google.maps.LatLng(
          results[0].geometry.location.lat(),
          results[0].geometry.location.lng(),
        );
        if (typeof callback === 'function') {
          callback(point);
        }
      }
    });
  }
}

export {
  AUMap,
  AUMapMarker,
  AUMapPolygon,
  AUMapRoute,
  AUMapGeocode,
  AUMapHelpers,
};

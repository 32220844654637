const labels = {
  da: {
    header: 'Find medarbejdere og bygninger',
    search: 'Søg efter bygning eller person',
    searchPH: 'Bygningsnr., navn, e-mail eller tlf.',
    building: 'Bygning',
    showOnMap: 'Vis på bygningskortet',
    personsInBuilding: 'Personer i bygning',
    topSearches: 'Mest søgte',
    routeButton: 'Rutevejledning',
    routeGuideHeadline: 'Hjælp til rutevejledning',
    routeGuideStart: 'Vælg dit startpunkt – du kan angive adresse, bygningsnummer eller navn på en AU-medarbejder',
    routeGuideEnd: 'Vælg din destination – du kan angive adresse, bygningsnummer eller navn på en AU-medarbejder',
    car: 'Bil',
    walk: 'Gå',
    bicycle: 'Cykel',
    public: 'Offentlig transport',
    close: 'Luk',
    switch: 'Byt',
    directLink: 'Direkte link til kortvisning med den valgte person, enhed eller bygning',
  },
  en: {
    header: 'Find staff and buildings',
    search: 'Search for building or person',
    searchPH: 'Bld. number, name, email or phone',
    building: 'Building',
    showOnMap: 'Show on building map',
    personsInBuilding: 'Persons in building',
    topSearches: 'Top searches',
    routeButton: 'Directions',
    routeGuideHeadline: 'How to get directions',
    routeGuideStart: 'Choose your starting point – you can enter an address, building number or a name of an AU employee',
    routeGuideEnd: 'Choose your destination – you can enter an address, building number or a name of an AU employee',
    car: 'Car',
    walk: 'Walk',
    bicycle: 'Bicycle',
    public: 'Public transport',
    close: 'Close',
    switch: 'Switch',
    directLink: 'Direct link to the map with the selected person, department or building',
  },
};

export default labels;

/* eslint import/no-cycle: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Suggestions from '../lib/Suggestions';

const groupMap = {
  0: 'denmark',
  1: 'lecture-hall',
  3: 'library',
  4: 'friday-bar',
  7: 'study-place',
  8: 'canteen',
  9: 'counselling',
  11: 'it-support',
  15: 'myprint',
};

const getSuggestionClass = (result) => {
  const className = ' au-map__suggestion__';
  if (result.type === '0') {
    return `${className}building`;
  }
  if (result.type === '1') {
    return `${className}department`;
  }
  if (result.type === '2') {
    return `${className}${groupMap[result.groupid]}`;
  }
  return '';
};

class SuggestionComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: props.result,
    };
  }

  componentDidMount() {
    const { result } = this.state;
    if (result.departmentsdetails) {
      Suggestions.getDepartmentDetails(result, [], (department) => {
        this.setState({ result: department }); // TODO: bygninger
      });
    } else if (result.type) {
      Suggestions.getBuildingDetails(result, [], (building) => {
        this.setState({ result: building });
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.result.type) {
      if (nextProps.result.id !== prevState.result.id) {
        return {
          result: nextProps.result,
        };
      }
    } else if (nextProps.result.id !== prevState.result.id) {
      return {
        result: nextProps.result,
      };
    }

    return null;
  }

  render() {
    const { result } = this.state;

    if (result.type) {
      return (
        <span className={`au-map__suggestion${getSuggestionClass(result)}`}>
          {result.suggestion}
        </span>
      );
    }

    return (
      <div className="row row--align-left au-map__person-suggestion">
        {
          result.photo !== '' && (
            <div className="row__item">
              <img src={result.photo} alt={result.fullName} />
            </div>
          )
        }
        <div className="row__item">
          <h4>
            <strong>
              {result.fullName}
            </strong>
          </h4>
          {
            (result.departments.length > 0) && (
              <p>
                {result.departments[0].name}
              </p>
            )
          }
        </div>
      </div>
    );
  }
}

SuggestionComponent.propTypes = {
  result: PropTypes.shape({
    type: PropTypes.string,
    uuid: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
};
SuggestionComponent.displayName = 'SuggestionComponent';
export default SuggestionComponent;

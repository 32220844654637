/* eslint-env browser */
import React from 'react';
import ReactDOM from 'react-dom';
import BuildingMapComponent from '../components/BuildingMapComponent';

let lang = 'da';
if (typeof window.appLang !== 'undefined') {
  lang = window.appLang;
} else if (typeof window.pageLang === 'undefined') {
  lang = document.querySelector('html').getAttribute('lang');
}

function waitForGoogle(callback) {
  const interval = setInterval(() => {
    if (window.google) {
      clearInterval(interval);
      callback();
    }
  }, 100); // Check every 100ms
}

const App = () => [
  <BuildingMapComponent
    key={1}
    lang={lang}
    container="au_map_building"
    width="100%"
    height={400}
    zoom={15}
    center={{
      lat: 56.168995,
      lng: 10.203113,
    }}
    scrollable
    draggable
    clickable
    streetview
    allowGeolocation={false}
    overlays={[0, 1, 3, 4, 7, 8, 9, 11, 15, 'bus']}
    showFakeMap={false}
  />,
];

function startReactApp() {
  ReactDOM.render(<App />, document.querySelector('.au-map'));
}

waitForGoogle(startReactApp);

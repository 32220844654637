/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import AUCollapsibleComponent from '@aarhus-university/au-lib-react-components/src/components/AUCollapsibleComponent';
import AUContentToggleComponent from '@aarhus-university/au-lib-react-components/src/components/delphinus/AUContentToggleComponent';

const BuildingCardComponent = ({
  id, mapUrl, names, address, persons, personClick, labels, forPopup,
}) => {
  const renderPersons = persons.map((p) => (
    <li key={p.fullName}>
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          personClick([p]);
        }}
      >
        {p.fullName}
      </button>
    </li>
  ));

  return (
    <div className="bld-card">
      <h2>
        <strong>
          {names[0].name}
        </strong>
      </h2>
      {
        (names.length > 1) && (
          <h4>
            <strong>
              {names.slice(1).map((n) => n.name).join(', ')}
            </strong>
          </h4>
        )
      }
      <p>
        {address}
      </p>
      {
        (persons.length > 0 && !forPopup) && (
          <>
            {
              !!window.delphinus && (
                <AUContentToggleComponent
                  toggled={false}
                >
                  <button
                    type="button"
                    className="content-toggle__toggle button button--small"
                  >
                    {labels.personsInBuilding}
                  </button>
                  <div className="content-toggle__content vertical-spacing-top" hidden>
                    <ul>
                      {renderPersons}
                    </ul>
                  </div>
                </AUContentToggleComponent>
              )
            }
            {
              !window.delphinus && (
                <AUCollapsibleComponent
                  collapsed
                  header={labels.personsInBuilding}
                  level={3}
                >
                  <div className="person-list">
                    <ul>
                      {renderPersons}
                    </ul>
                  </div>
                </AUCollapsibleComponent>
              )
            }
          </>
        )
      }
      <p className="show-on-map">
        <a href={`//www.au.dk/${mapUrl}?b=${id}`}>
          {labels.showOnMap}
        </a>
      </p>
      <hr />
    </div>
  );
};

BuildingCardComponent.propTypes = {
  id: PropTypes.string.isRequired,
  mapUrl: PropTypes.string.isRequired,
  names: PropTypes.arrayOf(PropTypes.shape(PropTypes.any.isRequired)).isRequired,
  address: PropTypes.string.isRequired,
  persons: PropTypes.arrayOf(PropTypes.any).isRequired,
  personClick: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    building: PropTypes.string.isRequired,
    showOnMap: PropTypes.string.isRequired,
    personsInBuilding: PropTypes.string,
  }).isRequired,
  forPopup: PropTypes.bool.isRequired,
};
BuildingCardComponent.displayName = 'BuildingCardComponent';
export default BuildingCardComponent;

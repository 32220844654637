import React from 'react';
import PropTypes from 'prop-types';

const OverlayCardComponent = ({
  id, names, text, selected, setFocus, forPopup,
}) => {
  if (forPopup) {
    if (text) {
      return <div dangerouslySetInnerHTML={{ __html: text }} />;
    }
    return names[0].name;
  }

  if (selected) {
    return (
      <li className="overlay-card">
        <strong>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setFocus(id);
            }}
          >
            {names[0].name}
          </button>
        </strong>
      </li>
    );
  }

  return (
    <li className="overlay-card">
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          setFocus(id);
        }}
      >
        {names[0].name}
      </button>
    </li>
  );
};

OverlayCardComponent.defaultProps = {
  text: null,
};

OverlayCardComponent.propTypes = {
  id: PropTypes.string.isRequired,
  names: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  text: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  setFocus: PropTypes.func.isRequired,
  forPopup: PropTypes.bool.isRequired,
};
OverlayCardComponent.displayName = 'OverlayCardComponent';
export default OverlayCardComponent;

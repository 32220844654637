/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-env browser */
/* eslint react/no-danger: 0 */
/* eslint jsx-a11y/label-has-for: 0 */
import 'url-search-params-polyfill';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { parse as qsParse } from 'query-string';
import AUCollapsibleComponent from '@aarhus-university/au-lib-react-components/src/components/AUCollapsibleComponent';
import AUContentToggleComponent from '@aarhus-university/au-lib-react-components/src/components/delphinus/AUContentToggleComponent';
import AutoSuggestComponent from '@aarhus-university/au-lib-react-components/src/components/AutoSuggestComponent';
import VCardComponent from './VCardComponent';
import BuildingCardComponent from './BuildingCardComponent';
import OverlayCardComponent from './OverlayCardComponent';
import DirectionsComponent from './DirectionsComponent';
import {
  AUMap, AUMapMarker, AUMapRoute, AUMapGeocode, AUMapPolygon,
} from '../lib/AUMap';
import { T3_HOST, IPURE_HOST, IPURE_RESULTS } from '../actions/const';
import Suggestions from '../lib/Suggestions';
import labels from '../lib/i18n';

const routeClass = 'au-map__route';

const generateDirectLink = (results) => {
  let urlString = '';
  results.forEach((result) => {
    if (result.type === '0') {
      urlString = `?b=${result.id}`;
    }
    if (result.type === '1') {
      urlString = `?e=${result.id}`;
    }
    if (result.type === '2') {
      urlString = `?os=${result.id}`;
    }
    if (typeof result.type === 'undefined') {
      urlString = `?p=${result.suggestion}`;
    }
  });
  return urlString;
};

class BuildingMapComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      auMap: null,
      route: null,
      showDirections: false,
      showRoute: false,
      locations: [],
      overlays: [],
      results: [],
      query: '',
      overlaySelected: -1,
      focusLocation: null,
    };

    this.getDataPath = this.getDataPath.bind(this);
    this.getLocations = this.getLocations.bind(this);
    this.setResults = this.setResults.bind(this);
    this.setQuery = this.setQuery.bind(this);
    this.setRoute = this.setRoute.bind(this);
    this.getCard = this.getCard.bind(this);
    this.setOverlay = this.setOverlay.bind(this);
    this.setFocus = this.setFocus.bind(this);
    this.parseQueryStrings = this.parseQueryStrings.bind(this);
    this.toggleDirectionsPanel = this.toggleDirectionsPanel.bind(this);
    this.clearRoute = this.clearRoute.bind(this);
  }

  componentDidMount() {
    const { allowGeolocation, showFakeMap } = this.props;
    const loadData = () => {
      this.getLocations((locations) => {
        if (!this.parseQueryStrings()) {
          if (allowGeolocation && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
              const nearest = AUMap.getIdMarkerNearestToLocation(position, locations);
              this.setResults([nearest], true, false);
            }, () => {
            }, { timeout: 5000 });
          }
        }
        this.getOftenSearch(locations);
      });
    };

    if (showFakeMap) {
      loadData();
    } else {
      this.setMap(() => loadData());
    }
  }

  setMap(callback) {
    const {
      container,
      width,
      height,
      zoom,
      center,
      scrollable,
      draggable,
      clickable,
      streetview,
    } = this.props;
    const auMap = new AUMap(
      document.getElementById(container),
      width,
      height,
      zoom,
      center,
      scrollable,
      draggable,
      clickable,
      streetview,
      () => { },
      {},
      {},
      {},
      (map) => {
        const route = new AUMapRoute(map, document.querySelector(`.${routeClass}`));
        this.setState({
          auMap: map,
          route,
        }, () => callback(auMap, route));
      },
    );
  }

  getDataPath() {
    const { overlays, lang } = this.props;
    const overlayQuery = overlays.length > 0 ? (`&overlays=${overlays.join(',')}`) : '';
    const langQuery = `?lang=${(lang === 'da' ? 1 : 2)}`;
    return T3_HOST + langQuery + overlayQuery;
  }

  getLocations(callback) {
    fetch(this.getDataPath()).then((promise) => promise.json()).then((data) => {
      const { locations } = data;
      const { overlays } = this.state;
      locations.forEach((l) => {
        if (l.type === '2') {
          const existingOverlays = overlays.filter((x) => x.id === parseInt(l.groupid, 10));
          if (existingOverlays.length === 0) {
            overlays.push({
              id: parseInt(l.groupid, 10),
              name: l.groupname,
            });
          }
        }
      });
      this.setState({
        locations,
        overlays: overlays.sort((a, b) => (a.name > b.name ? 1 : -1)),
      }, () => {
        if (typeof callback === 'function') {
          callback(locations);
        }
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getOftenSearch(locations, query = '') {
    const url = `${IPURE_HOST}${IPURE_RESULTS}`;
    if (query === '') {
      fetch(url);
    } else {
      const params = new URLSearchParams();
      params.append('q', query);
      fetch(url, {
        method: 'POST',
        body: params,
      });
    }
  }

  getCard(result, forPopup = false) {
    const { lang } = this.props;
    const { locations, focusLocation } = this.state;
    if (!result.type) {
      const pureUrl = result.departments.length > 0 ? `http://au.dk/${result.departments[0].email}` : '';
      const mailTo = result.departments.length > 0 ? `mailto:${result.departments[0].email}` : '';
      const { fullName, photo, departments } = result;

      return (
        <VCardComponent
          key={result.id}
          pureUrl={pureUrl}
          mapUrl={lang === 'da' ? 'kort/' : 'en/map/'}
          mailTo={mailTo}
          fullName={fullName}
          photo={photo}
          departments={departments}
          buildingClick={this.setResults}
          locations={locations}
          labels={{
            building: labels[lang].building,
            showOnMap: labels[lang].showOnMap,
          }}
          forPopup={forPopup}
        />
      );
    }

    if (!result.groupid) {
      return (
        <BuildingCardComponent
          key={result.id}
          id={result.id}
          mapUrl={lang === 'da' ? 'kort/' : 'en/map/'}
          names={result.names}
          address={result.address}
          persons={result.persons || []}
          personClick={this.setResults}
          labels={{
            building: labels[lang].building,
            showOnMap: labels[lang].showOnMap,
            personsInBuilding: labels[lang].personsInBuilding,
          }}
          forPopup={forPopup}
        />
      );
    }

    return (
      <OverlayCardComponent
        key={result.id}
        id={result.id}
        names={result.names}
        text={result.text}
        selected={result.id === focusLocation}
        setFocus={this.setFocus}
        forPopup={forPopup}
      />
    );
  }

  setMarker(result, lat, lng) {
    const { auMap } = this.state;
    const map = auMap;
    const marker = new AUMapMarker(
      map, { lat, lng }, 'https://cdn.au.dk/2015/maps/graphics/marker_generic.png',
      false,
      true,
      true,
      true,
      12,
      ReactDOMServer.renderToStaticMarkup(this.getCard(result, true)),
      result.id,
    );
    map.setCenter(marker);
  }

  setResults(newResults, clear = true, updateResults = true) {
    const {
      auMap,
      results: sResults,
      overlaySelected,
      locations,
    } = this.state;
    const { center } = this.props;

    const results = clear ? [] : sResults;

    const showOnMap = (map) => {
      this.setState({
        results: [...results, ...newResults],
        overlaySelected: newResults.length === 1 ? -1 : overlaySelected,
        showRoute: false,
      }, () => {
        if (map) {
          if (newResults.length === 0 && clear) {
            map.clearMarkers();
          }
          newResults.forEach((result, i) => {
            if (i === 0 && clear) {
              map.clearMarkers();
              map.clearPolygons();
            }
            // Building or location
            if (result.latitude && result.longitude) {
              if (result.coordinates?.length > 0) {
                // eslint-disable-next-line max-len
                this.drawPolygon(result.coordinates.map((c) => ({ lat: c.latitude, lng: c.longitude })), parseFloat(result.latitude), parseFloat(result.longitude));
              } else {
                this.setMarker(result, parseFloat(result.latitude), parseFloat(result.longitude));
              }
              if (updateResults) {
                this.getOftenSearch(locations, result.id);
              }
            } else if (result.departments) {
              // Person
              result.departments.forEach((d) => {
                const { building } = d.address;
                const foundBuilding = building ? locations.find((x) => x.id === building.id) : null;
                if (foundBuilding) {
                  // eslint-disable-next-line max-len
                  this.drawPolygon(foundBuilding.coordinates.map((c) => ({ lat: c.latitude, lng: c.longitude })), parseFloat(foundBuilding.latitude), parseFloat(foundBuilding.longitude));
                } else {
                  const address = `${d.address.street} ${d.address.zip} ${d.address.city}`;
                  AUMapGeocode.lookupAddress(map, address, (point) => {
                    this.setMarker(result, point.lat(), point.lng());/*
                if (result.departments.length > 0) {
                  if (updateResults) {
                    this.getOftenSearch(this.state.locations, result.departments[0].email);
                  }
                } */
                  });
                }
              });
            } else {
              // Department
              AUMapGeocode.lookupAddress(map, result.address, (point) => {
                this.setMarker(result, point.lat(), point.lng());
                if (updateResults) {
                  this.getOftenSearch(locations, result.id);
                }
              });
            }
          });
          if (newResults.length > 1) {
            map.setBounds(
              center,
              newResults.filter((x) => parseInt(x.groupid, 10) === 0).length > 0,
            );
          }
        }
      });
    };

    if (!auMap) {
      this.setMap((map) => showOnMap(map));
    } else {
      showOnMap(auMap);
    }
  }

  setQuery(query, callback = () => { }) {
    this.setState({
      query,
    }, () => {
      callback();
    });
  }

  setRoute() {
    const {
      auMap, route: sRoute, query, locations,
    } = this.state;
    const showMap = (route) => {
      AUMapRoute.findRoutePattern(query, locations, (parsedQuery) => {
        if (parsedQuery) {
          route.calc(parsedQuery.from, parsedQuery.to, parsedQuery.method, () => {
            this.setState({
              results: [],
              showRoute: true,
            });
          });
        }
      });
    };

    if (!auMap) {
      this.setMap((_, route) => showMap(route));
    } else {
      this.clearRoute();
      showMap(sRoute);
    }
  }

  setOverlay(id) {
    const { locations } = this.state;
    const { overlaySelected } = this.state;
    if (id !== overlaySelected) {
      this.setState({
        overlaySelected: id,
      }, () => {
        this.setResults(locations
          .filter((x) => parseInt(x.groupid, 10) === id), true, false);
      });
    } else {
      this.setState({
        overlaySelected: -1,
      }, () => {
        this.setResults([]);
      });
    }
  }

  setFocus(id) {
    const { locations, auMap } = this.state;
    const focusLocation = locations.find((x) => x.id === `${id}`);
    this.setState({
      focusLocation: focusLocation.id,
    }, () => {
      auMap.panTo({
        lat: focusLocation.latitude,
        lng: focusLocation.longitude,
      }, 17, focusLocation.id);
    });
  }

  drawPolygon(coordinates, centerLat, centerLng) {
    const { auMap } = this.state;
    const map = auMap;
    // eslint-disable-next-line no-new
    new AUMapPolygon(map, coordinates, centerLat, centerLng);
  }

  clearRoute() {
    const { route } = this.state;
    if (route) {
      route.clear();
      document.querySelector(`.${routeClass}`).innerHTML = '';
    }
  }

  toggleDirectionsPanel() {
    this.setState((prevState) => ({
      showDirections: !prevState.showDirections,
      showRoute: false,
    }), () => {
      this.clearRoute();
    });
  }

  parseQueryStrings() {
    const { locations, overlays } = this.state;
    const qs = qsParse(window.location.search);
    const param = qs.b || qs.p || qs.e || qs.os;
    if (param) {
      Suggestions.getSuggestions(param, locations, (newResults) => {
        const promises = [];
        newResults.forEach((r) => {
          if (!r.type) {
            promises.push(new Promise((resolve) => {
              resolve(r);
            }));
          } else if (r.departmentsdetails) {
            promises.push(new Promise((resolve) => {
              Suggestions.getDepartmentDetails(r, locations, (department) => {
                resolve(department);
              });
            }));
          } else {
            promises.push(new Promise((resolve) => {
              Suggestions.getBuildingDetails(r, locations, (building) => {
                resolve(building);
              });
            }));
          }
        });
        Promise.all(promises).then((values) => {
          this.setResults(values);
        });
      }, true);

      return true;
    }
    if (qs.o) {
      this.setOverlay(overlays.find((o) => o.id === parseInt(qs.o, 10)).id);
    }

    return false;
  }

  render() {
    const {
      auMap,
      results,
      locations,
      overlays,
      overlaySelected,
      showRoute,
      showDirections,
    } = this.state;
    const { lang, container, height } = this.props;
    const renderResults = results.map((r) => this.getCard(r));

    let mapColumnClass = 'column large-12 medium-12 small-12 row__item row__item--basis-20';
    if (results.length > 0 || showRoute) {
      mapColumnClass = 'column large-9 medium-9 small-12 row__item row__item--basis-20';
    }
    const resultColumnClass = 'column large-3 medium-3 small-12 row__item';

    const renderOverlays = overlays.map((o) => (
      <button
        key={o.id}
        type="button"
        className="button small"
        onClick={(e) => {
          e.preventDefault();
          this.setOverlay(o.id);
        }}
      >
        {o.name}
      </button>
    ));

    const onlyResultIsOverlay = results.length === 1 && results[0].type === '2';
    let overlayHeader = '';
    if (overlaySelected > -1) {
      overlayHeader = overlays.find((x) => x.id === overlaySelected).name;
    }
    if (onlyResultIsOverlay) {
      overlayHeader = overlays.find((x) => x.id === parseInt(results[0].groupid, 10)).name;
    }

    return (
      <>
        {
          (typeof window.mapHeaderIsH2 === 'undefined' || !window.mapHeaderIsH2) && (
            <h1 className="pagetitle">{labels[lang].header}</h1>
          )
        }
        {
          (typeof window.mapHeaderIsH2 !== 'undefined' && window.mapHeaderIsH2) && (
            <h2>{labels[lang].header}</h2>
          )
        }
        <div className="row">
          <div className={mapColumnClass}>
            {
              !showDirections && [
                <form
                  key="search"
                  className="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.setRoute();
                  }}
                >
                  <div className="au-map__search">
                    <div className="form__field">
                      <AutoSuggestComponent
                        id="au-map-buildings-autocomplete"
                        placeholder={labels[lang].search}
                        setQuery={this.setQuery}
                        setResults={this.setResults}
                        collection={locations}
                        getSuggestions={Suggestions.getSuggestions}
                        getSuggestionValue={Suggestions.getSuggestionValue}
                        renderSuggestion={Suggestions.renderSuggestion}
                        theme={window.delphinus ? {
                          container: 'autosuggest',
                          containerOpen: 'autosuggest--open',
                          input: 'autosuggest__input',
                          inputOpen: 'autosuggest__input--open',
                          inputFocused: 'autosuggest__input--focused',
                          suggestionsContainer: 'autosuggest__suggestions',
                          suggestionsContainerOpen: 'autosuggest__suggestions--open',
                          suggestionsList: 'autosuggest__suggestions__list',
                          suggestion: 'autosuggest__suggestions__list__item',
                          suggestionFirst: 'autosuggest__suggestions__list--first',
                          suggestionHighlighted: 'autosuggest__suggestions__list--highlighted',
                          sectionContainer: 'autosuggest__section',
                          sectionContainerFirst: 'autosuggest__section--first',
                          sectionTitle: 'autosuggest__section__title',
                        } : undefined}
                        clearInput
                      />
                    </div>
                    <button
                      type="button"
                      className="button button--icon--right button--icon button--dimmed"
                      data-icon=""
                      onClick={this.toggleDirectionsPanel}
                    >
                      <span>{labels[lang].routeButton}</span>
                    </button>
                  </div>
                </form>,
                <div key="overlays" className="au-map__overlay-buttons">
                  {renderOverlays}
                </div>,
              ]
            }
            {
              showDirections && (
                <DirectionsComponent
                  lang={lang}
                  setQuery={this.setQuery}
                  setRoute={this.setRoute}
                  toggleDirectionsPanel={this.toggleDirectionsPanel}
                />
              )
            }
            <div
              id={container}
              style={{
                height,
                backgroundImage: 'url(\'https://cdn.au.dk/res/images/maps/background.jpg\')',
                backgroundPosition: 'center center',
              }}
              onClick={() => {
                if (!auMap) {
                  this.setMap(() => { });
                }
              }}
            />
            {
              results.length === 1 && (
                <a className="a--text-link" href={`${window.location.href.replace(window.location.search, '')}${generateDirectLink(results)}`}>{labels[lang].directLink}</a>
              )
            }
          </div>
          {
            (results.length > 0) && (
              <div className={resultColumnClass}>
                {
                  (overlaySelected > -1 || onlyResultIsOverlay) && (
                    <>
                      {
                        !!window.delphinus && (
                          <AUContentToggleComponent
                            toggled={false}
                          >
                            <button
                              type="button"
                              className="content-toggle__toggle button button--small"
                            >
                              {overlayHeader}
                            </button>
                            <ul className="content-toggle__content vertical-spacing-top" hidden>
                              {renderResults}
                            </ul>
                          </AUContentToggleComponent>
                        )
                      }
                      {
                        !window.delphinus && (
                          <AUCollapsibleComponent
                            collapsed={false}
                            header={overlayHeader}
                            level={2}
                          >
                            <ul>
                              {renderResults}
                            </ul>
                          </AUCollapsibleComponent>

                        )
                      }
                      <hr />
                    </>
                  )
                }
                {
                  !(overlaySelected > -1 || onlyResultIsOverlay) && (
                    <>
                      {renderResults}
                    </>
                  )
                }
              </div>
            )
          }
          <div className={resultColumnClass} style={{ display: showRoute ? 'block' : 'none' }}>
            <div className={routeClass} />
          </div>
        </div>
      </>
    );
  }
}

BuildingMapComponent.defaultProps = {
  showFakeMap: true,
};

BuildingMapComponent.propTypes = {
  lang: PropTypes.string.isRequired,
  container: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  zoom: PropTypes.number.isRequired,
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  scrollable: PropTypes.bool.isRequired,
  draggable: PropTypes.bool.isRequired,
  clickable: PropTypes.bool.isRequired,
  streetview: PropTypes.bool.isRequired,
  allowGeolocation: PropTypes.bool.isRequired,
  overlays: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  showFakeMap: PropTypes.bool,

};
BuildingMapComponent.displayName = 'BuildingMapComponent';
export default BuildingMapComponent;

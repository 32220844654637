import React from 'react';
import PropTypes from 'prop-types';
import Suggestions from '../lib/Suggestions';

const VCardComponent = ({
  pureUrl, mapUrl, mailTo, fullName, photo, departments, buildingClick, locations, labels, forPopup,
}) => {
  const renderDepartments = departments.map((d) => {
    const bldStr = d.address.building ? `${labels.building} ${d.address.building.id}` : '';
    const roomStr = d.address.building.room ? `, ${d.address.building.room}` : '';
    return [
      <h3 key={0} className="org">
        {d.name}
      </h3>,
      <p key={1}>
        {
          (bldStr !== '' && !forPopup) && [
            <span key={0}>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  Suggestions.getBuildingDetails(d.address.building, locations, (building) => {
                    buildingClick([building]);
                  });
                }}
              >
                {bldStr}
              </button>
              {roomStr}
            </span>,
            <br key={1} />,
          ]
        }
        {
          (bldStr !== '' && forPopup) && [
            <span key={0}>
              {bldStr}
              {roomStr}
            </span>,
            <br key={1} />,
          ]
        }
        <span className="adr">
          <span className="street-address">
            {d.address.street}
          </span>
          <br />
          <span className="postal-code">
            {d.address.zip}
          </span>
          {' '}
          <span className="locality">
            {d.address.city}
          </span>
        </span>
      </p>,
    ];
  });
  return (
    <div className="vcard">
      <h2 className="fn">
        <a className="url" href={pureUrl}>
          {fullName}
        </a>
      </h2>
      <div>
        {
          photo !== '' && (
            <a className="photo" href={pureUrl}>
              <img src={photo} alt={fullName} title={fullName} />
            </a>
          )
        }
        {renderDepartments}
        <p>
          {
            departments[0].phone && [
              <span key={0}>
                T:
                {' '}
              </span>,
              <span key={1} className="tel">
                {departments[0].phone}
              </span>,
              <br key={2} />,
            ]
          }
          {
            departments[0].mobile && [
              <span key={0}>
                M:
                {' '}
              </span>,
              <span key={1} className="tel">
                {departments[0].mobile}
              </span>,
              <br key={2} />,
            ]
          }
          {
            departments[0].email && [
              <span key={0}>
                E:
                {' '}
              </span>,
              <a key={1} className="email" href={mailTo}>
                {departments[0].email}
              </a>,
              <br key={2} />,
            ]
          }
        </p>
        <p className="show-on-map">
          <a href={`//www.au.dk/${mapUrl}?p=${departments[0].email}`}>
            {labels.showOnMap}
          </a>
        </p>
      </div>
      <hr />
    </div>
  );
};

VCardComponent.propTypes = {
  pureUrl: PropTypes.string.isRequired,
  mapUrl: PropTypes.string.isRequired,
  mailTo: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  departments: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  labels: PropTypes.shape(PropTypes.any.isRequired).isRequired,
  buildingClick: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  forPopup: PropTypes.bool.isRequired,
};
VCardComponent.displayName = 'VCardComponent';
export default VCardComponent;

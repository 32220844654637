import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import labels from '../lib/i18n';

const DirectionsComponent = ({
  lang,
  setQuery,
  setRoute,
  toggleDirectionsPanel,
}) => {
  const [mode, setMode] = useState('BIL');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const getDirections = () => {
    const query = `FRA ${from} TIL ${to} ${mode}`;
    setQuery(query, setRoute);
  };

  useEffect(() => {
    if (from.length > 3 && to.length > 3) {
      getDirections();
    }
  }, [mode, from, to]);

  return (
    <div className="au-map__directions-panel">
      <div className="au-map__directions-panel__modes-of-transportation button-container">
        <button
          type="button"
          className={`button button--icon--hide-label button--small button--icon${mode === 'BIL' ? '' : ' button--text'} button-route button-route--car${mode === 'BIL' ? ' button-route--selected' : ''}`}
          data-icon=""
          onClick={() => {
            setMode('BIL');
          }}
        >
          {labels[lang].car}
        </button>
        <button
          type="button"
          className={`button button--icon--hide-label button--small button--icon${mode === 'GÅ' ? '' : ' button--text'} button-route button-route--walk${mode === 'GÅ' ? ' button-route--selected' : ''}`}
          data-icon=""
          onClick={() => {
            setMode('GÅ');
          }}
        >
          {labels[lang].walk}
        </button>
        <button
          type="button"
          className={`button button--icon--hide-label button--small button--icon${mode === 'CYKEL' ? '' : ' button--text'} button-route button-route--bicycle${mode === 'CYKEL' ? ' button-route--selected' : ''}`}
          data-icon=""
          onClick={() => {
            setMode('CYKEL');
          }}
        >
          {labels[lang].bicycle}
        </button>
        <button
          type="button"
          className={`button button--icon--hide-label button--small button--icon${mode === 'OFFENTLIG' ? '' : ' button--text'} button-route button-route--public${mode === 'OFFENTLIG' ? ' button-route--selected' : ''}`}
          data-icon=""
          onClick={() => {
            setMode('OFFENTLIG');
          }}
        >
          {labels[lang].public}
        </button>
        <button
          type="button"
          className="button button--icon--hide-label button--small button--icon button--text button-route button-route--close"
          data-icon=""
          onClick={toggleDirectionsPanel}
        >
          {labels[lang].close}
        </button>
      </div>
      <div className="au-map__directions-panel__from-to">
        <div className="form__field">
          <input
            type="text"
            placeholder={labels[lang].routeGuideStart}
            value={from}
            onChange={(e) => {
              setFrom(e.target.value);
            }}
          />
        </div>
        <div className="form__field">
          <input
            type="text"
            placeholder={labels[lang].routeGuideEnd}
            value={to}
            onChange={(e) => {
              setTo(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="au-map__directions-panel__swap">
        <button
          type="button"
          className="button button--icon--hide-label button--small button--icon button-route button-route--swap"
          data-icon=""
          onClick={() => {
            const f = from;
            const t = to;
            setFrom(t);
            setTo(f);
          }}
        >
          {labels[lang].switch}
        </button>
      </div>
    </div>
  );
};

DirectionsComponent.displayName = 'DirectionsComponent';
DirectionsComponent.propTypes = {
  lang: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  setRoute: PropTypes.func.isRequired,
  toggleDirectionsPanel: PropTypes.func.isRequired,
};

export default DirectionsComponent;

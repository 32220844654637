/* eslint-disable no-restricted-globals */
/* eslint-env browser */
/* eslint import/no-cycle: 0 */
import React from 'react';
import fuzzysort from 'fuzzysort';
import SuggestionComponent from '../components/SuggestionComponent';
import {
  IPURE_HOST, IPURE_SEARCH_PEOPLE, IPURE_SEARCH_BUILDINGS, IPURE_SEARCH_DEPARTMENTS,
} from '../actions/const';
import { AUMapRoute } from './AUMap';

// https://github.com/farzher/fuzzysort
const getMatchScore = (query, person) => {
  const results = fuzzysort.go(query, [person], { key: 'fullName' });
  if (results.length === 1) {
    return results[0].score;
  }
  return 0;
};

const isNumeric = (str) => {
  if (typeof str !== 'string') {
    return false;
  }
  return !isNaN(str) && !isNaN(parseFloat(str));
};

class Suggestions {
  static getDepartmentDetails(_department, locations, callback) {
    const department = _department;
    if (!department.detailsLoaded) {
      const url = `${IPURE_HOST}${IPURE_SEARCH_DEPARTMENTS}?mode=json&q=${department.id}`;
      fetch(url).then((promise) => promise.json()).then((data) => {
        if (data.length === 1) {
          const dep = data[0];
          department.address = dep.address;
          department.detailsLoaded = true;
          if (typeof callback === 'function') {
            callback(department);
          }
        }
      });
    }
  }

  static getBuildingDetails(_building, locations, callback) {
    let building = _building;
    if (!building.detailsLoaded && building.id.length >= 4) {
      building = locations.length === 0 ? building : locations.find((x) => x.id === building.id);
      if (building) {
        const url = `${IPURE_HOST}${IPURE_SEARCH_BUILDINGS}?q=${building.id}`;
        fetch(url).then((promise) => promise.json()).then((data) => {
          const persons = data;
          building.persons = persons || [];
          building.detailsLoaded = true;
          if (typeof callback === 'function') {
            callback(building);
          }
        }).catch(() => {
          building.persons = [];
          callback(building);
        });
      }
    } else if (building.detailsLoaded || building.type === '2') {
      callback(building);
    }
  }

  static getSuggestions(value, collection, callback, fromQueryString = false) {
    if (typeof value === 'string') {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const isNumber = isNumeric(inputValue);

      let url = `${IPURE_HOST}${IPURE_SEARCH_PEOPLE}?mode=json&q=${value}`;
      const stopWords = AUMapRoute.getStopWords();
      const foundStopWord = !stopWords.every((w) => value.trim().indexOf(w) === -1);
      if ((inputLength < 3 && (!isNumber || !fromQueryString)) || foundStopWord) {
        callback([]);
        // Gør ingenting, hvis der er TASTET mindre end to tegn,
        // eller hvis der er fundet ord til rutevejledning
      } else if (inputLength > 2 || isNumber) {
        // Hvis der er TASTET mere end to tegn,
        // eller hvis input kommer fra URL (som et tal/id)
        const locationResults = inputLength === 0 ? [] : collection.filter((x) => {
          const loc = x;
          let found = loc.id === inputValue && (loc.type !== '2' || fromQueryString); // Matcher præcis id på lokation (hvis tal/id fra URL)
          if (loc.type !== '2') { // Forsøg: hvis ikke "overlays" i søgning
            if (!found && (!isNumber || !fromQueryString)) {
              loc.names.forEach((n, index) => {
                if (loc.type !== '0') {
                  // Hvis det er andet end bygning, matcher vi søgning fra starten af name
                  if (n.name.toLowerCase().indexOf(inputValue) === 0) {
                    loc.suggestion = loc.names[0].name;
                    found = true;
                  }
                } else {
                  // Hvis det er en bygning, spliter vi name op
                  // så det fx bliver ['Bygning', '1445']
                  const splitInput = inputValue.split(' ');
                  let foundSplit = false;
                  splitInput.forEach((s, i) => {
                    if ((i === 0 || foundSplit) && n.name.toLowerCase().indexOf(s) > -1) {
                      foundSplit = true;
                    } else {
                      foundSplit = false;
                    }
                  });
                  if (foundSplit) {
                    // Hvis der er søgt på en bygnings alternative navn, vises det i parentes
                    loc.suggestion = `${loc.names[0].name}${index > 0 ? ` (${loc.names[index].name})` : ''}`;
                    found = true;
                  }
                }
              });
            } else {
              loc.suggestion = loc.names[0].name;
            }
          }
          return found;
        });

        if (locationResults.length > 0) {
          callback(locationResults.slice(0, 20)); // Forsøg: vi viser kun 20 bygninger i suggestions
          // Hvis vi ikke har fundet noget i data, søger vi efter en person
        } else if (typeof callback === 'function') {
          if (isNumber || inputValue.includes('@')) {
            url = `${url}&exact=true`;
          }
          fetch(url).then((promise) => promise.json()).then((data) => {
            // eslint-disable-next-line max-len
            const sortedData = data.sort((a, b) => (getMatchScore(inputValue, a) > getMatchScore(inputValue, b) ? -1 : 1));
            callback(sortedData);
          });
        }
      }
    }
  }

  static getSuggestionValue(suggestion) {
    return suggestion.suggestion;
  }

  static renderSuggestion(suggestion) {
    return (
      <SuggestionComponent result={suggestion} />
    );
  }
}

export default Suggestions;
